import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import {graphql} from 'gatsby'
import styles from './team.module.css'

const Author = ({name, pictureFluid, area, linkTitle, linkHref}) => {
  return (
    <div className={styles.authorCard}>
      <Img fluid={pictureFluid} style={{height: '300px'}} />
      <div className={styles.authorContent}>
        <h1>{name}</h1>
        <h2>{area}</h2>
        {linkTitle && linkHref ? <h3><a href={linkHref}>{linkTitle}</a></h3> : null}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allLedgerCsv {
      edges {
        node {
          date
          handle
          type
          hours
          euros
          source
        }
      }
    }
    allAuthorsCsv {
      edges {
        node {
          handle
          name
          area
          picture
          linkTitle
          linkHref
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid {
            src
            aspectRatio
            sizes
            srcSet
            originalName
          }
        }
      }
    }
  }
`

export default function TeamPage({data}) {
  const FIVE_MONTHS = 5 * 31 * 24 * 60 * 60 * 1000

  const pictures = data.allImageSharp.edges.map(({node}) => node.fluid)
  const entries = data.allLedgerCsv.edges.map(({node}) => node)

  const authors = data.allAuthorsCsv.edges
    .map(({node}) => {
      const author = node
      const pictureFluid = pictures.find(p => p.originalName === author.picture)
      if (pictureFluid) {
        author.pictureFluid = pictureFluid
      }
      author.hours_volunteered = entries.reduce((acc, entry) => {
        const hours = parseFloat(entry.hours)
        if (
          entry.handle === author.handle &&
          entry.type === 'volunteered_hours' &&
          typeof hours === 'number'
        ) {
          return acc + hours
        } else {
          return acc
        }
      }, 0)
      author.hours_worked = entries.reduce((acc, entry) => {
        const hours = parseFloat(entry.hours)
        if (
          entry.handle === author.handle &&
          entry.type === 'worked_hours' &&
          typeof hours === 'number'
        ) {
          return acc + hours
        } else {
          return acc
        }
      }, 0)
      author.hours = author.hours_volunteered + author.hours_worked
      const lastEntry = entries
        .filter(entry => entry.handle === author.handle)
        .sort((a, b) => b.date.localeCompare(a.date))[0]
      author.last_contribution = lastEntry ? lastEntry.date : Date.now()
      return author
    })
    .sort((a, b) => b.hours - a.hours)

  const currentAuthors = authors.filter(
    author =>
      Date.now() - new Date(author.last_contribution).getTime() <= FIVE_MONTHS
  )

  const pastAuthors = authors.filter(
    author =>
      Date.now() - new Date(author.last_contribution).getTime() > FIVE_MONTHS
  )

  return (
    <>
      <Layout>
        <SEO title="Team" />
        <h1>Meet the Manyverse contributors</h1>
        <p>
          This list updates as volunteers come and go, anyone can appear in this
          list if they abide by our{' '}
          <a href="https://gitlab.com/staltz/manyverse/-/blob/master/code-of-conduct.md">
            code of conduct
          </a>{' '}
          and volunteer to push Manyverse forwards.
        </p>
        <h2>Active contributors</h2>
        <div style={{marginTop: '3em'}} />
        <div className={styles.authors}>
          {currentAuthors.map(props => (
            <Author {...props} key={props.name} />
          ))}
        </div>
        <h2>Former contributors</h2>
        <div style={{marginTop: '3em'}} />
        <div className={styles.authors}>
          {pastAuthors.map(props => (
            <Author {...props} key={props.name} />
          ))}
        </div>
        <p>
          You can see a full list of people who have contributed code to
          Manyverse in the{' '}
          <a href="https://gitlab.com/staltz/manyverse/-/blob/master/AUTHORS">
            AUTHORS
          </a>{' '}
          file.
        </p>
        <div style={{marginTop: '6em'}} />
      </Layout>
    </>
  )
}
